/* Fichier CSS pour BellumUrsisPage */

/* Applique un fond dégradé pour la page */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Assure que le composant occupe toute la hauteur de l'écran */
  background: rgb(250, 250, 250);
  background: linear-gradient(
    0deg,
    rgba(250, 250, 250, 1) 75%,
    rgba(0, 34, 124, 1) 75%
  );
  padding: 2%;
  text-align: center;
  color: rgba(0, 34, 124, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; /* Pour que le composant soit au-dessus des autres éléments */
}

/* Style du logo */
.page-logo {
  width: 16%; /* Taille du logo */
  height: 20%;
  margin-bottom: 24px; /* Espacement sous le logo */
  border-radius: 5%; /* Bord arrondi pour le logo */
  border: 2px solid white; /* Bordure blanche autour du logo */
  box-shadow: 10px 10px 5px rgba(12, 12, 12, 0.411);
}

/* Titre de la page */
.page-title {
  font-size: 2.5rem; /* Taille du titre */
  font-weight: bold;
  margin-bottom: 16px; /* Espacement sous le titre */
}

/* Description de la page */
.page-description {
  color:  rgb(233, 3, 3); /* Couleur de la description */
  margin-bottom: 24px; /* Espacement sous la description */
}

/* Style des boutons */
.page-buttons a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  margin: 10px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px; /* Bord arrondi des boutons */
  transition: all 0.3s ease;
  color: white;
}

.page-buttons .youtube {
  background-color: #ff0000; /* Couleur YouTube */
}

.page-buttons .tiktok {
  background-color: #000000; /* Couleur TikTok */
}

.page-buttons a:hover {
  transform: scale(1.05); /* Effet de zoom au survol */
}

/* Couleur des boutons au survol */
.page-buttons .youtube:hover {
  background-color: #cc0000;
}

.page-buttons .tiktok:hover {
  background-color: #333333;
}
.mailTo a {
  color: rgba(0, 34, 124, 1);
  text-decoration: none; /* Supprimer le soulignement par défaut */
  font-weight: bold; /* Mettre le texte en gras */
  transition: color 0.3s ease; /* Transition fluide sur la couleur */
  font-size: 1.5rem;
}

.mailTo a:hover {
  color: blue; /* Changer la couleur en bleu au survol */
}

@media screen and (max-width: 768px) {
  .page-container {
    padding: 5%;
  }
  .page-logo {
    width: 50%; /* Taille du logo pour les petits écrans */
  }
  .page-title {
    font-size: 1.6rem; /* Taille du titre pour les petits écrans */
  }
  .page-description {
    font-size: 1rem; /* Taille de la description pour les petits écrans */
  }
  .page-buttons a {
    font-size: 0.9rem; /* Taille des boutons pour les petits écrans */
  }
}
