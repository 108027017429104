.txtStory {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2%;
  justify-content: space-around;
  background-color: rgb(239, 255, 255);
  padding: 5%;
  border-radius: 20px;
  cursor: pointer;
  color: rgb(20, 146, 3);
  font-size: 1.2rem;
}

@media screen and (max-width: 1000px) {
  .lapinImg {
    width: 50%;
  }

  .txtStory {
    font-size: 1rem;
    padding: 5%;
  }
}
