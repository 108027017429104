/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #4fd3ab;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #0cc406;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #f7faf8;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  overflow: hidden;
}

/* General sidebar styles */
.bm-menu {
  background: #3f6347;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
  height: 99% !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #ececeb;
  padding: 0.8em;
  overflow: hidden;
  height: 90%;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/* Individual item */
.bm-item,.menu-item {
  display: inline-block;
  color: #f4f5f4;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover,
.logoutLink:hover,
.avatar:hover,
.menu-item:hover {
  color: #0dc906;
}

.logoutLink,
.loginSide,
.menu-item {
  text-decoration: none;
  color: white;
}

.avatar {
  position: fixed;
  left: 38px;
  top: 80px;
  color: #4fd3ab;
}
