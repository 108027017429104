/* styles.css */

/* Style de base pour le tableau */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

/* Style de l'en-tête du tableau */
th {
  background-color: #3498db;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

/* Style des cellules du tableau */
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

/* Style pour les lignes impaires du tableau */
tr:nth-child(odd) {
  background-color: #f2f2f2;
}
/* Style pour les lignes impaires du tableau */
tr:nth-child(even) {
  background-color: #04664881;
  color: #f3f4f5;
}

/* Style pour les liens */
a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Style pour la page principale */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Style pour la page des détails du cours */
.course-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.course-details h2,
p,
li {
  color: #3498db;
}

h4 {
  color: #068d01;
  text-decoration: underline;
}
.course-details p {
  margin-top: 10px;
}

/* styles.css */

/* ... autres styles ... */

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  border: 2px solid rgb(135, 12, 235);
}

.buttonReturn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  margin-left: 30%;
  margin-right: 30%;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

.button:hover,
.buttonReturn:hover {
  background-color: rgb(250, 151, 168);
  text-decoration: none;
  text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
  border: 2px solid rgb(135, 12, 235);
  color: purple;
}

.courseListItem {
  display: flex;
  justify-content: space-between;
  border: none;
  margin: 1%;
  margin-bottom: -1%;
}

.happyRobot001 {
  width: 20%;
}

.robotIcon {
  width: 2%;
}

.iconKawaii {
  width: 10%;
}
.iconKawaii2 {
  width: 3.5%;
  margin-right: 5%;
  margin-left: 5%;
}

.courseList {
  display: flex;
  align-items: center;
}

.kawaii001Icon {
  height: 3rem;
  margin-right: 2%;
  transform: rotate(8deg) skew(0deg, 0deg) !important;
}

.kawaii002Icon {
  height: 3rem;
  margin-left: 2%;
  transform: rotate(8deg) skew(0deg, 0deg) !important;
}

.mangaPanel {
  text-shadow: -2px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  -moz-transform: rotate(355deg) skew(8deg, 0deg);
  -webkit-transform: rotate(355deg) skew(8deg, 0deg);
  -o-transform: rotate(355deg) skew(8deg, 0deg);
  -ms-transform: rotate(355deg) skew(8deg, 0deg);
  transform: rotate(356deg) skew(0deg, 0deg);
  color: rgb(98, 7, 173);
  border: 4px solid;
  margin-bottom: -1%;
  background-color: rgb(250, 151, 168);
  padding: 1.5%;
  border-radius: 8px;
  width: 50%;
}

.robotIcon:hover,
.iconKawaii:hover {
  /* Styles lorsque l'élément est survolé */
  animation: vibrate-1 0.3s linear infinite;
}

.vibrate-1 {
  -webkit-animation: vibrate-1 0.3s linear infinite both;
  animation: vibrate-1 0.3s linear infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-8-7 21:35:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.puff-out-center {
  -webkit-animation: puff-out-center 2s cubic-bezier(0.165, 0.84, 0.44, 1) 1s
    infinite both;
  animation: puff-out-center 2s cubic-bezier(0.165, 0.84, 0.44, 1) 1s infinite
    both;
}

.puff-out-center2 {
  -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1s
    infinite both;
  animation: puff-out-center 2s cubic-bezier(0.165, 0.84, 0.44, 1) 1s infinite
    both;
}

.puff-out-center3 {
  -webkit-animation: puff-out-center 1.6s cubic-bezier(0.165, 0.84, 0.44, 1) 1s
    infinite both;
  animation: puff-out-center 2.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1s infinite
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-8-7 21:45:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

.puff-out-br {
  -webkit-animation: puff-out-br 2s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s
    infinite both;
  animation: puff-out-br 2s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s infinite
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-8-7 21:47:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-br
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-br {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-br {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

.puff-out-bl {
  -webkit-animation: puff-out-bl 1.9s cubic-bezier(0.165, 0.84, 0.44, 1) 2.2s
    infinite both;
  animation: puff-out-bl 1.9s cubic-bezier(0.165, 0.84, 0.44, 1) 2.2s infinite
    both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-7 21:48:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-bl {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-bl {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
.puff-out-center {
  -webkit-animation: puff-out-center 2s cubic-bezier(0.165, 0.84, 0.44, 1) 2s
    infinite both;
  animation: puff-out-center 2s cubic-bezier(0.165, 0.84, 0.44, 1) 2s infinite
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-8-7 21:55:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

.happyRobot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.happyRobot2 {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* CoursDetails.css */

.course-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.tableCotent {
  list-style-type: none; /* Masquer les puces */
}

.course-details h2 {
  color: #3498db;
  margin-bottom: 10px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important;
}

.description {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.author,
.duration {
  color: #777;
  font-size: 14px;
}

#scrollToTopButton {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

#scrollToTopButton.show {
  display: block;
}

.scroll-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: opacity 0.3s;
}

.scroll-to-top-button.show {
  display: block;
}
