.homepage {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  color: rgb(234, 240, 236);
}
.mainHomeContainer {
  margin-top: 2%;
  flex-direction: row;
  width: 80%;
  height: 100%;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
 
}

.link-styles,
.link-styles2,
.link-styles3 {
  color: white;
  text-decoration: none;
}

.link-styles:hover {
  color: rgb(1, 206, 63);
  text-decoration: none;
}

.link-styles2:hover {
  color: rgb(17, 250, 200);
  text-decoration: none;
}

.link-styles3:hover {
  color: rgb(98, 235, 71);
  text-decoration: none;
}
.itemHeader {
  margin-top: 0;
  color: rgb(255, 255, 255);
  text-shadow: -2px 0 black,0 2px black, 2px 0 black, 0 -2px black;
  font-size: 1.5rem;
}

.item001 {
  background-size: cover;
 /* border: 4px solid rgb(192, 73, 4);*/
  background-image: url("/public/lightGreen.png");
  border-radius: 25px 25px 25px 25px;
  display: flex;
  align-items: flex-end;
  padding: 4%;
  margin: 2%;
  color: black;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.521);
}

.item002 {
  background-size: cover;
  /*border: 4px solid rgb(24, 4, 138);*/
  border-radius: 25px 25px 25px 25px;
  display: flex;
  align-items: flex-end;
  padding: 4%;
  margin: 2%;
  color: black;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.521);
  background-image: url("/public/green_red.png");
}
.item004 {
  display: flex;
  align-items: flex-end;
  background-size: cover;
  /*border: 4px solid rgb(24, 4, 138);*/
  border-radius: 25px 25px 25px 25px;
  padding: 4%;
  margin: 2%;
  color: black;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.521);
  background-image: url("/public/background_bleuRed.png");
}
.item003 {
 /* border: 4px solid rgb(51, 153, 51);*/
  display: flex;
  align-items: flex-end;
  background-size: cover;
  border-radius: 25px 25px 25px 25px;
  padding: 4%;
  margin: 2%;
  color: black;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.521);
  background-image: url("/public/yellow.png");
}

.robotIconMain{
  width: 8%;
  transform: rotate(22deg) skew(0deg, 0deg)!important;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconItem {
  color: rgb(249, 253, 5);
}
.itemTxt {
  -moz-transform: rotate(355deg) skew(8deg, 0deg);
  -webkit-transform: rotate(355deg) skew(8deg, 0deg);
  -o-transform: rotate(355deg) skew(8deg, 0deg);
  -ms-transform: rotate(355deg) skew(8deg, 0deg);
  transform: rotate(356deg) skew(0deg, 0deg);
  color: rgb(255, 255, 255);
  text-shadow: -1px 0 black,0 2px black, 2px 0 black, 0 -1px black;
  margin-bottom: -1%;
  padding: 1.5%;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-6 10:59:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.shadow-drop-center {
  -webkit-animation: shadow-drop-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: shadow-drop-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-6 11:11:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 0.8s both;
  animation: wobble-hor-bottom 0.8s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-7-18 11:22:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}


/* Styles for screens with maximum width of 768px (e.g., smartphones) */
@media screen and (max-width: 768px) {
 
  .mainHomeContainer{
    display: flex;
  }
}